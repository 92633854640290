import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import EventForm from './components/EventForm';
import EventList from './components/EventList';
import EventPage from './pages/EventPage';
import TicketPage from './pages/TicketPage';
import QRScanner from './components/QRScanner';
import ResultPage from './pages/ResultPage';
import './styles/App.css';
import './styles/css/framework7-bundle.css';
import './styles/css/framework7-icons.css';
import './styles/style.css';


function App() {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    // Load events from localStorage on startup
    const storedEvents = JSON.parse(localStorage.getItem('events')) || [];
    setEvents(storedEvents);
  }, []);

  const addEventToList = (event) => {
    setEvents([...events, event]);
  };

  return (
    <Router>
      <div className="app">
        <Routes>
          <Route path="/" element={
            <>
              <EventList events={events} />
              <EventForm addEventToList={addEventToList} />
            </>
          } />
          <Route path="/event/:id" element={<EventPage />} />
          <Route path="/ticket-details/:id" element={<TicketPage />} /> 
          {/* Updated path */}
          <Route path="/qr-scanner" element={<QRScanner />} />
          <Route path="/result" component={ResultPage} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
