import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { fetchTickets, syncTickets } from '../services/api';

const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error('Error reading localStorage:', error);
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error('Error setting localStorage:', error);
    }
  };

  return [storedValue, setValue];
};

function EventPage() {
  const { id } = useParams();
  const [tickets, setTickets] = useLocalStorage('tickets', []);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [isSyncing, setIsSyncing] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) {
      console.error('Event ID is missing.');
      navigate('/');
      return;
    }

    const getTickets = async () => {
      if (tickets.length === 0) {
        try {
          const fetchedTickets = await fetchTickets(id);
          setTickets(fetchedTickets);
        } catch (error) {
          setTickets([]);
        }
      }
    };

    getTickets();
  }, [id, tickets.length, navigate, setTickets]);

  const handleSyncTickets = async () => {
    setIsSyncing(true);
    try {
      const updatedTickets = await syncTickets(tickets);
      setTickets(updatedTickets);
      setIsSyncing(false);
    } catch (error) {
      setIsSyncing(false);
    }
  };

  useEffect(() => {
    if (searchQuery) {
      setFilteredTickets(
        tickets.filter(ticket =>
          ticket.ticket_id.toLowerCase().includes(searchQuery.toLowerCase()) ||
          ticket.ticket_ref.toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    } else {
      setFilteredTickets(tickets);
    }
  }, [searchQuery, tickets]);

  return (
    <div className='page-content'>
    <div className="event-page">
    <div className="search-wrapper">
  <div className="left search-input-container">
    <input
      type="text"
      placeholder="Search by Ticket ID or Reference"
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      className="search-input"
    />
  </div>
</div>
<p class="grid grid-cols-2 grid-gap grid-buttons-demo">
    <Link className='button button-fill color-light-gray' onClick={() => navigate(-1)}><i className="icon f7-icons">arrow_left</i> Back</Link>
    <button className="button button-fill color-dark" onClick={handleSyncTickets} disabled={isSyncing}>
        {isSyncing ? 'Syncing...' : 'Sync Tickets'}
      </button>
    </p>

      {filteredTickets.length > 0 ? (

        <div class="post-list">
           {filteredTickets.map((ticket) => (
      <Link to={`/ticket-details/${ticket.id}`} class="link post-horizontal"  key={ticket.id}>
        <div class="infos">
          <div class="post-category">Ticket ID: {ticket.ticket_id}</div>
          <div class="post-title">Type: {ticket.ticket_type}</div>
          <div class="post-date">Status: {ticket.checked_in ? 'Verified' : 'Active'}</div>
        </div>
      </Link>
           ))}
    </div>

      ) : (
        <p>No tickets available for this event.</p>
      )}

      <button className="floating-button" onClick={() => navigate('/qr-scanner')}>
      <i className="f7-icons">qrcode_viewfinder</i>
      </button>
    </div>
    </div>
  );
}

export default EventPage;
