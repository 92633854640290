import React from 'react';
import { useParams } from 'react-router-dom';
import TicketDetails from '../components/TicketDetails';

function TicketPage() {
  const { ticketId } = useParams();

  return (
    <div className="ticket-page">
      <TicketDetails ticketId={ticketId} />
    </div>
  );
}

export default TicketPage;
