import React from 'react';
import { useLocation } from 'react-router-dom';

function ResultPage() {
  const { state } = useLocation();
  const { message } = state || { message: 'No result to display.' };

  return (
    <div className="result-page">
      <h2>Scan Result</h2>
      <p>{message}</p>
    </div>
  );
}

export default ResultPage;
