import axios from 'axios';

const apiUrl = 'https://rest.hapniin.com/momoApi/v1'; // Replace with your actual API URL


export const addEvent = async (eventCode) => {
  try {
    const response = await axios.post(`${apiUrl}/event/check`, { eventCode });
    return response.data;
  } catch (error) {
    console.error('Error adding event:', error);
    return null;
  }
};

export const getTickets = async (eventId) => {
  try {
    const response = await axios.get(`${apiUrl}/event/${eventId}/tickets`);
    return response.data;
  } catch (error) {
    console.error('Error fetching tickets:', error);
    return [];
  }
};

export const fetchTickets = async (eventId) => {
  try {
    const response = await axios.get(`${apiUrl}/event/${eventId}/tickets`);
    return response.data.tickets;
  } catch (error) {
    console.error('Error fetching tickets:', error);
    throw error;
  }
};

export const syncTickets = async (localTickets) => {
  try {
    const response = await axios.post(`${apiUrl}/ticket/sync`, { localTickets });
    return response.data.updatedTickets;
  } catch (error) {
    console.error('Error syncing tickets:', error);
    throw error;
  }
};

export const verifyTicket = async (ticketId) => {
  try {
    const response = await axios.post(`${apiUrl}/ticket/verify`, { ticketId });
    return response.data;
  } catch (error) {
    console.error('Error verifying ticket:', error);
    return null;
  }
};

// export const getTicketDetails = async (ticketId) => {
//   try {
//     const response = await axios.get(`${apiUrl}/ticket/${ticketId}`);
//     return response.data;
//   } catch (error) {
//     console.error('Error fetching ticket details:', error);
//     return null;
//   }
// };
