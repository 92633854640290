import React from 'react';
import { Link } from 'react-router-dom';

function EventList({ events }) {
  return (
    <div className='page-content'>
    <div className="event-list">
    {/* Navigation Bar with centered title */}
    <nav className="navbar">
      <h1>Event Manager</h1>
    </nav>
      {events.length > 0 ? (
        <div class="list detailed-list list-dividers full-width">
          {events.map((event, index) => (

            <Link key={index} to={`/event/${event.event.id}`}  class="link link-banner dark-theme-green link-chevron">
              <i class="icon f7-icons">tickets</i>
              <div class="link-banner-text">
                <div class="link-banner-title">{event.event.title}</div>
                <div class="link-banner-subtitle">Date: {new Date(event.event.start).toLocaleDateString()}</div>
                </div>
            </Link>
          ))}
      </div>

        
      ) : (
        <p>No stored events yet</p>
      )}
    </div>
    </div>
  );
}

export default EventList;
