import React, { useState } from 'react';
import { addEvent } from '../services/api';

function EventForm({ addEventToList }) {
  const [eventCode, setEventCode] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!eventCode) return;

    const event = await addEvent(eventCode);
    if (event) {
      // Add event to localStorage
      let storedEvents = JSON.parse(localStorage.getItem('events')) || [];
      storedEvents.push(event);
      localStorage.setItem('events', JSON.stringify(storedEvents));

      addEventToList(event);  // Update the list in the UI
      setEventCode('');
    } else {
      alert('Event not found!');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="event-form">
    <div className="grid grid-cols-2 grid-gap grid-no-margin">
        <div>
       <input
         type="text"
         value={eventCode}
        placeholder="Enter Event Code"
        onChange={(e) => setEventCode(e.target.value)}
        required
       />
        </div>
        <div className='event-bt'>
          <button type="submit"  class="button button-fill color-dark">Submit</button>
        </div>
      </div>
      </form>
    
  );
}

export default EventForm;
