import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

function TicketDetailsPage() {
  const { id } = useParams(); // Get the ticket ID from the URL
  const [ticket, setTicket] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const getTicketDetailsFromLocalStorage = () => {
      try {
        // Retrieve the tickets array from localStorage
        const storedTickets = JSON.parse(localStorage.getItem('tickets')) || [];

        console.log('Stored tickets:', storedTickets); // Debugging log

        // Find the ticket by ID (id might be a string, so ensure type matching)
        const ticketDetails = storedTickets.find(
          (ticket) => String(ticket.id) === String(id)
        );

        if (ticketDetails) {
          setTicket(ticketDetails);
          console.log('Found ticket:', ticketDetails); // Debugging log
        } else {
          setErrorMessage('Ticket not found.');
          console.log('Ticket not found for ID:', id); // Debugging log
        }
      } catch (error) {
        setErrorMessage('Error fetching ticket details from localStorage.');
        console.error('Error:', error); // Debugging log
      }
    };

    getTicketDetailsFromLocalStorage();
  }, [id]);

  const handleVerify = () => {
    if (ticket.checked_in) {
      setErrorMessage('This ticket has already been verified.');
    } else {
      // Update ticket as checked-in
      const updatedTicket = { ...ticket, checked_in: true };

      // Update the tickets in localStorage
      const storedTickets = JSON.parse(localStorage.getItem('tickets')) || [];
      const updatedTickets = storedTickets.map((t) =>
        t.id === id ? updatedTicket : t
      );
      localStorage.setItem('tickets', JSON.stringify(updatedTickets));

      setTicket(updatedTicket);
      setErrorMessage('Ticket successfully verified.');
    }
  };

  if (!ticket) {
    return <p>Loading ticket details...</p>;
  }

  return (
    <div className="ticket-details-page">
      <h2>Ticket Details</h2>
      <div className="ticket-info">
        <p><strong>Ticket ID:</strong> {ticket.ticket_id}</p>
        <p><strong>Reference:</strong> {ticket.ticket_ref}</p>
        <p><strong>Type:</strong> {ticket.ticket_type}</p>
        <p><strong>Status:</strong> 
          <span className={`badge ${ticket.checked_in ? 'badge-success' : 'badge-primary'}`}>
            {ticket.checked_in ? 'Verified' : 'Active'}
          </span>
        </p>
        <p><strong>Holder's Name:</strong> {ticket.holders_name}</p>
        <p><strong>Category:</strong> 
          {ticket.ticket_category}
          <span className={`badge ${ticket.is_paid ? 'badge-success' : 'badge-tertiary'}`} style={{ marginLeft: '10px' }}>
            {ticket.is_paid ? 'Paid' : 'Complimentary'}
          </span>
        </p>
        <p><strong>Purchase Date:</strong> {new Date(ticket.date_created).toLocaleDateString()}</p>
        <p><strong>Purchased By:</strong> {ticket.assigned_by}</p>
        <p><strong>Assigned To:</strong> {ticket.assigned_to}</p>
      </div>

      {errorMessage && <p className="error-message">{errorMessage}</p>}

      {!ticket.checked_in && (
        <button onClick={handleVerify} className="btn btn-success">
          Mark as Verified
        </button>
      )}

      <button onClick={() => navigate(-1)} className="btn btn-secondary">
        Back to Event Page
      </button>
    </div>
  );
}

export default TicketDetailsPage;
