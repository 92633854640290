import React, { useState, useEffect, useRef } from 'react';
import { BrowserMultiFormatReader } from '@zxing/browser'; // Import ZXing
import { Link, useNavigate } from 'react-router-dom';

function QRScanner() {
  const [resultMessage, setResultMessage] = useState(''); // For displaying result or error
  const [videoDevices, setVideoDevices] = useState([]); // Store available video devices
  const [selectedDeviceId, setSelectedDeviceId] = useState(null); // Store the selected camera's deviceId
  const videoRef = useRef(null); // Ref for the video element
  const readerRef = useRef(null); // ZXing reader
  const [scannedTicket, setScannedTicket] = useState(null); // Store the scanned ticket details
  const [isScanning, setIsScanning] = useState(false); // Track scanning state
  const navigate = useNavigate();

  // Get available video devices (cameras)
  const getVideoDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoInputDevices = devices.filter(device => device.kind === 'videoinput');
      setVideoDevices(videoInputDevices);

      if (videoInputDevices.length === 0) {
        setResultMessage('No camera devices found.');
      } else {
        const backCamera = videoInputDevices.find(device => device.label.toLowerCase().includes('back')) || videoInputDevices[0];
        setSelectedDeviceId(backCamera?.deviceId || null);
      }
    } catch (error) {
      console.error('Error fetching video devices:', error);
      setResultMessage('Error fetching camera devices.');
    }
  };

  // Fetch tickets from localStorage
  const fetchTicketsFromLocalStorage = () => {
    try {
      const tickets = JSON.parse(localStorage.getItem('tickets')) || [];
      return tickets;
    } catch (error) {
      console.error('Error reading tickets from localStorage:', error);
      return [];
    }
  };

  // Handle scanning and ticket verification locally
  const handleScanResult = (code) => {
    if (code) {
      console.log('Scanned code:', code);
      const tickets = fetchTicketsFromLocalStorage();
      const ticket = tickets.find(ticket => ticket.ticket_id === code);

      if (ticket) {
        if (ticket.checked_in) {
          setResultMessage('Ticket has already been verified.');
          setScannedTicket(ticket);
          setIsScanning(false);
          stopCamera(); // Stop camera after successful scan
        } else {
          ticket.checked_in = true;
          localStorage.setItem('tickets', JSON.stringify(tickets)); // Save updated tickets to localStorage
          setResultMessage('Ticket verified successfully.');
          setScannedTicket(ticket);
          stopCamera(); // Stop camera after successful scan
        }
      } else {
        setResultMessage('Ticket not found or invalid.');
        setScannedTicket(null);
      }
    } else {
      setResultMessage('No valid QR code detected.');
      setScannedTicket(null);
    }
  };

  // Start the video stream and ZXing QR code reader
  const startCamera = async () => {
    if (!selectedDeviceId) return;

    try {
      // Start the media stream for the selected device
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: { exact: selectedDeviceId } },
      });

      videoRef.current.srcObject = stream;

      videoRef.current.onloadedmetadata = () => {
        videoRef.current.play();
      };

      // Set up ZXing reader
      readerRef.current = new BrowserMultiFormatReader();
      setIsScanning(true);
      readerRef.current.decodeFromVideoDevice(
        selectedDeviceId, // The camera ID to use
        videoRef.current, // The video element
        (result, err) => {
          if (result) {
            handleScanResult(result.getText()); // Process the QR code result
          }
          if (err && err.name !== 'NotFoundException') {
            console.error('Error scanning QR code: ', err);
          }
        }
      );
    } catch (error) {
      console.error('Error starting camera or setting up reader:', error);
      setResultMessage('Error accessing camera.');
    }
  };

  // Stop the camera and release media streams
  const stopCamera = () => {
    if (readerRef.current && typeof readerRef.current.reset === 'function') {
      readerRef.current.reset(); // Stop ZXing reader
    }
    if (videoRef.current && videoRef.current.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach(track => track.stop()); // Stop all tracks
      videoRef.current.srcObject = null; // Clear the video source
    }
    setIsScanning(false);
  };

  useEffect(() => {
    getVideoDevices(); // Fetch available video devices (cameras) on component mount
  }, []);

  useEffect(() => {
    if (selectedDeviceId) {
      startCamera(); // Start the camera feed when the device is selected
    }

    return () => {
      stopCamera(); // Stop the camera when component unmounts
    };
  }, [selectedDeviceId]);

  // Handle camera switching
  const handleSwitchCamera = () => {
    if (videoDevices.length <= 1) return; // No other camera to switch to

    const currentIndex = videoDevices.findIndex(device => device.deviceId === selectedDeviceId);
    const nextIndex = (currentIndex + 1) % videoDevices.length;
    setSelectedDeviceId(videoDevices[nextIndex]?.deviceId || null);
    
    // Stop the current scanning process
    stopCamera();
  };

  const handleScanAgain = () => {
    setScannedTicket(null); // Reset to scanner view
    setResultMessage(''); // Clear message
    startCamera(); // Restart the camera
  };

  const handleStartScan = () => {
    // User action to trigger camera access
    if (!isScanning) {
      startCamera();
    }
  };

  return (
    <div className="page-content">
      <div className="qr-scanner">
        <div className="light-gray-title"><h2>Scan a Ticket</h2></div>

        {!scannedTicket ? (
          <>
            <div className="video-wrapper">
              <video ref={videoRef} style={{ width: '100%' }} />
            </div>
            <div className="result">
              <p>{resultMessage || 'Awaiting scan result...'}</p>
            </div>
            {!isScanning && (
              <button className='button button-fill color-dark' onClick={handleStartScan}>
                Start Scanning
              </button>
            )}
          </>
        ) : (
          <div className="ticket-details">
            <div className="information-block success-message">
              <h2>Verified!</h2>
              <p>Ticket verification was successful</p>
              <p>Ticket ID: {scannedTicket.ticket_id}</p>
              <p>Type: {scannedTicket.ticket_type}</p>
              <p>Holder: {scannedTicket.holders_name}</p>
            </div>
            <Link className='button button-fill color-dark' onClick={() => navigate(-1)}>Scan Another Ticket</Link>
          </div>
        )}

        {videoDevices.length > 1 && (
          <button className='button button-fill color-light-gray' onClick={handleSwitchCamera}>
            Switch Camera
          </button>
        )}
      </div>
    </div>
  );
}

export default QRScanner;
